@import ../../../../../public/styles/helpers.sass

.hero
  position: relative
  display: flex
  text-align: center
  align-items: center
  min-height: 880px
  padding: 34px 0 0
  +m
    text-align: start
    align-items: start
    min-height: auto
    padding: 64px 0 0

.stage
  margin-bottom: 16px


.wrap
  position: relative
  z-index: 3
  width: 100%
  max-width: 836px
  margin: 0 auto
  display: flex
  flex-flow: column nowrap
  align-items: center
  +m
    align-items: start
.title
  color: $neutrals2
  max-width: 100%
  margin-bottom: 20px
  +tomato

.text
  margin-bottom: 40px
  color: $neutrals4
  font-size: 24px
  line-height: 32px
  p
    margin-bottom: 12px

.btns
  display: flex
  align-items: center
  a
    cursor: pointer
  +m
    width: 100%
    a
      width: 100%

.button
  +m
    padding: 0 22px

.gallery
  width: 100%
  pointer-events: none
  margin: 64px auto

.preview
  &:first-child
    position: relative
    z-index: 2
  &:nth-child(2)
    top: 10%
    right: 20%
    z-index: 3
    width: 20%
  &:nth-child(3)
    top: 47%
    left: 12%
    z-index: 1
    width: 8.5%
  &:not(:first-child)
    position: absolute
  img
    width: 100%
  +t
    margin: 0 -40px
  +m
    padding: 0 -32px
