@import ../../../../../../public/styles/helpers.sass

.section
    position: relative
    +m
        padding-top: 0
        background: none

.container
    padding: 0 40px
    +m
        padding: 0 16px

.gallery
    position: absolute
    pointer-events: none
    +x
        max-width: 580px
    +d
        position: relative
        margin: 0 auto 20px auto
    +m
        top: auto

.wrap
    position: relative
    z-index: 3
    max-width: 545px
    margin-left: auto
    padding: 48px
    background: $neutrals8
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1)
    border-radius: 20px
    +x
        max-width: 520px
    +d
        max-width: 500px
        padding: 32px
        margin: 0 auto
    +dark
        background: $neutrals1

.title
    color: $neutrals2
    margin-bottom: 24px
    +tomato

.info
    font-size: 16px
    line-height: 24px
    margin-bottom: 24px
    color: $neutrals4
    p
        padding: 0 0 10px 0

.list
    margin-bottom: 24px

.item
    display: flex
    padding: 20px
    border-radius: 12px
    transition: box-shadow .2s
    &:hover
        box-shadow: inset 0 0 0 2px $neutrals6
        +dark
            box-shadow: inset 0 0 0 2px $neutrals3
    &:not(:last-child)
        margin-bottom: 4px

.icon
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 40px
    height: 40px
    margin-right: 24px
    border-radius: 50%

.details
    flex-grow: 1

.subtitle
    margin-bottom: 4px
    font-weight: 600

.content
    max-width: 280px
    +caption-1
    color: $neutrals4

.green
    color: $green

.black
    color: $neutrals2
    +dark
        color: $neutrals8

.btns
    text-align: right

.button
    +m
        width: 100%