@import ../../../../../../public/styles/helpers.sass

.section
    position: relative

.top
    margin-bottom: 40px
    text-align: center
    +m
        margin-bottom: 48px

.title
    max-width: 600px
    margin: 0 auto
    color: $neutrals2
    +tomato
    margin-bottom: 20px

.info
    max-width: 650px
    color: $neutrals4
    font-size: 16px
    line-height: 24px
    margin: 0 auto
    color: #12141D

.btns
    display: flex
    a
        cursor: pointer

.button
    margin: 0 auto
    +m
        padding: 0 22px
    &:not(:last-child)
        margin-right: 16px

.connectImage
    position: absolute
    width: 588px
    height: 406px
    bottom: 0
    right: 0
    +x
        display: none